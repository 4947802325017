/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
export const wrapPageElement = ({ element }) => {
  return (
      <Router>{element}</Router>
  );
};
